<template>
	<div class="main">
		<template  v-if="dataList">
		<div class="goods-card-box" style="margin-bottom: 20px">
			<div class="flex goods-card-title">
				<span class="top-title">{{dataList.despositoryAllot.allotNo}}</span>
				<span>
					<tag v-if="dataList.despositoryAllot.status === 2" color="rgba(247, 75, 75, 0.1)" text-color="#F74B4B" size="large" >已拒绝</tag>
					<tag v-else-if="dataList.despositoryAllot.status === 1" color="rgba((0, 155, 96, 0.1)" text-color="#00A868" size="large" >已完成</tag>
					<tag v-else color="rgba(35, 127, 240, 0.1)" text-color="#237FF0" size="large" >待处理</tag>
				</span>
			</div>
			<div class="top-title">申请人：{{dataList.despositoryAllot.applyUserName}}</div>
			<div class="top-title2">调出仓：{{dataList.despositoryAllot.outDepositoryName}}</div>
			<div class="top-title2">调入仓：{{dataList.despositoryAllot.putDepositoryName}}</div>
			<div class="top-title2">创建时间：{{dataList.despositoryAllot.createTime}}</div>
		</div>
		<div class="goods-card-box">
			<div v-for="(goods, key) in dataList.goodsInfoList" :key="key" class="flex flex-column flex-start goods-card-goods-list">
				<div class="flex goods-card-goods-item">
					<div class="goods-card-goods-left"><van-image width="100" height="100" :src="goods.goodsImg"/></div>
					<div class="flex flex-column flex-start goods-card-goods-right">
						<div class="text-weight goods-title">{{goods.goodsName}}</div>
						<div class="unit-text">单位：{{goods.goodsUnit}}</div>
						<!--<div class="price-text">{{goods.purchasePrice}}元</div>-->
					</div>
				</div>
				<div class="flex goods-card-title item-line">
					<span>调拨数量</span>
					<span>{{goods.goodsNum}}</span>
				</div>
				<van-divider dashed></van-divider>
			</div>
		</div>
		<div class="order-status">
			<div class="order-status-title">审批信息</div>
			<div>
				<van-steps direction="vertical" active-color="#323233" :active="stepId">
					<van-step v-for="(item, index) in activities" :key="index">
						<h3>{{item.levelName}}</h3>
                        <div v-for="(item2, index2) in activities[index].auditorList" :key="index2">
                            <div class="steps-info-ok" v-if="item2.checkFlag === 1">
                                <div v-if="item2.auditorName !== ''">{{item2.auditorName}}</div>
                                <div v-if="item2.auditorName !== ''"><van-icon name="success" /></div>
                            </div>
							<div v-else-if="item2.checkFlag === 2">
								<div class="steps-info-rt">
									<div>{{item2.auditorName}}</div>
									<div><van-icon name="cross" /></div>
								</div>
								<div class="no-message">驳回原因：{{item2.message}}</div>
							</div>
                            <div v-else class="steps-info-no">
                                <div>{{item2.auditorName}}</div>
                            </div>
                        </div>
					</van-step>
				</van-steps>
			</div>
		</div>
		<div class="margin-box"></div>
		<div v-if="appStatus !== 2 && appStatus !== 3" class="button-box">
			<div @click="toOk" class="button-ok">通过</div>
			<div @click="toShowNo" class="button-no">驳回</div>
		</div>
		<van-popup
				v-model="show"
				closeable
				lock-scroll
				round
				close-icon="close"
				:style="{ height: '200px', width:'80vw' }"
		>
			<div class="message-box-text">
				<van-field
						v-model="rejectRemark"
						rows="2"
						autosize
						type="textarea"
						placeholder="请输入驳回原因"
				/>
			</div>
			<div class="message-box-button">
				<div @click="show = false" class="button-cancel">取消</div>
				<div @click="toNo" class="button-ok">确认</div>
			</div>
		</van-popup>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import { getAllotDetail, getApprovalV2, updateBillAll } from '@/api/service'
	import {Dialog, Tag, Toast} from 'vant';
	import { Empty } from 'vant';
	export default {
	  name: 'DemandList',
	  components: {
		  Tag,
		  Empty
	  },
	  data() {
	    return {
			allotNo: '', // 单号
			activities: '', // 流程列表
			dataList: {
				despositoryAllot:{
					applyUserName:'',
					allotNo:''
				},
				goodsInfoList:[]
			},
			addData: {
				allotNo: '',
				ispass: '',
				rejectMsg: '',
				payVoucher: ''
			},
			rejectRemark: '',
			show: false,
			stepId: 0,
			appStatus: 0 // 审批流状态
	    }},
	  created() {
		  const query = this.$route.query
		  if (!query.allotNo) {
			  Dialog.alert({title: '提示', message: '参数错误',})
			  return
		  }
		  this.allotNo = query.allotNo
		  this.addData.allotNo = query.allotNo
		  this.getInfo()
	  },
	  mounted:function(){
	  },
	  methods: {
	  	// 获取信息
		  getInfo(){
			  const postData = {}
			  postData.id = this.allotNo
			  getAllotDetail(postData).then(response => {
				  this.dataList = response.data;
				  console.log(this.dataList)
				  this.showApproval()
			  })
		  },
		  // 查看流程
		  showApproval() {
			  const postData = {}
			  postData.id = this.allotNo
			  getApprovalV2(postData).then(response => {
				  console.log(response)
				  this.activities = response.data.list
				  this.appStatus = response.data.status
                  // 判断进行到了哪一步
                  for (let i = 0; i < this.activities.length; i++) {
                  	if(this.activities[i].checkFlag === 1 || this.activities[i].checkFlag === 2){
                  		this.stepId = i
					}
				  }
			  })
		  },
		  // 提交通过
		  toOk(){
			  Dialog.confirm({
				  title: '通过',
				  message: '是否确认通过',
			  })
			  .then(() => {
				  // on confirm
				  this.saveData(2)
			  })
			  .catch(() => {
				  // on cancel
			  });
		  },
		  // 展示拒绝
		  toShowNo(){
			  this.show = true
		  },
		  // 提交拒绝
		  toNo(){
			  this.show = false
			  this.saveData(3)
		  },
		  // 提交保存
		  saveData(type) {
			  let isPass = ''
			  if(type == 2){
				  isPass = true
			  }else{
				  isPass = false
			  }
			  const postData = {}
			  postData.allotNo = this.allotNo
			  postData.ifPass = isPass
			  postData.noPassReason = this.rejectRemark
			  updateBillAll(postData).then((response) => {
			  	if(response){
					console.log(response)
					Dialog.alert({title: '提示', message: '操作成功',})
					this.getInfo()
					this.showApproval()
				}
			  })
		  },
	  }
	}
</script>

<style scoped>
	.main{
		padding: 20px;
		color:#333333;
	}
	.goods-card-box{
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.flex{
		display: flex;
	}
	.flex-column{
		flex-direction: column;
	}
	.flex-start{
		justify-content: flex-start;
	}
	.text-weight{
		font-weight: bold;
	}
	.top-title{
		font-size: 16px;
		font-weight: bold;
		margin: 0 0 20px 0 ;
	}
	.top-title2{
		font-size: 16px;
		margin: 0 0 20px 0 ;
	}
	.goods-card-title{
		justify-content: space-between;
	}
	.goods-card-goods-list{
	}
	.goods-card-goods-item{
		margin: 15px 0 15px 0;
	}
	.goods-title{
		width: 200px;
		font-size: 18px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 这里是超出几行省略 */
		overflow: hidden;
	}
	.goods-card-goods-left{
		padding-right: 15px;
	}
	.goods-card-goods-right{
	}
	.unit-text{
		height: 28px;
		line-height: 28px;
		background: #F5F6F7;
		border-radius: 4px;
		padding: 0 10px;
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		width:fit-content;
		width:-webkit-fit-content;
	    width:-moz-fit-content;
		margin-top: 10px;
	}
	.item-line{
		height: 30px;
		line-height: 30px;
	}
	.order-status{
		margin: 20px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		font-weight: bold;
		font-size: 16px;
	}
	.button-box{
		width: calc(100vw - 40px);
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		display: flex;
		justify-content: space-around;
		padding: 20px 0;
	}
	.button-ok{
		background-color: #237FF0;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-no{
		background-color: #FF7833;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-cancel{
		background-color: #abadaf;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.margin-box{
		width: 100%;
		height: 100px;
	}
	.message-box-text{
		margin-top: 40px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-around;
		width: 100%;
		margin-top: 38px;
	}
    .steps-info-ok{
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        color: #237FF0;
    }
	.steps-info-rt{
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
		color: #f83b51;
	}
    .steps-info-no{
        height: 25px;
        line-height: 25px;
    }
	.no-message{
		color: #f83b51;
	}
</style>
